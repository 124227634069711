import SvgColor from "components/svg-color/SvgColor";
import { PATH_DASHBOARD } from "routes/paths";

export const PATH_ROUTE = {
  dashboard: PATH_DASHBOARD.app,
  Master: "/app/master",
  course: PATH_DASHBOARD.courses,
  board: PATH_DASHBOARD.board,
  class: PATH_DASHBOARD.class,
  batchtype: PATH_DASHBOARD.batchtype,
  batchdate: PATH_DASHBOARD.batchdate,
  subject: PATH_DASHBOARD.subject,
  chapter: PATH_DASHBOARD.chapter,
  banner: PATH_DASHBOARD.banner,
  state: PATH_DASHBOARD.state,
  city: PATH_DASHBOARD.city,
  grievancesCategory: PATH_DASHBOARD.grievancescategory,
  grievancesSubcategory: PATH_DASHBOARD.grievancessubcategory,
  wanttobe: PATH_DASHBOARD.wanttobe,
  imagesbookmark: PATH_DASHBOARD.imagesbookmark,
  roles: PATH_DASHBOARD.roles,
  Syllabus: "/app/syllabus",
  topic: PATH_DASHBOARD.topic,
  videomanager: PATH_DASHBOARD.content,
  // videoManagerBulk: PATH_DASHBOARD.videoManagerBulk,
  uploadbulktopic: PATH_DASHBOARD.bulkupload,
  uploadbulkvideo: PATH_DASHBOARD.bulkuploadvideo,
  "Student Manager": "/app/student-manager",
  studentlist: PATH_DASHBOARD.student,
  ratings: PATH_DASHBOARD.ratings,
  uploadbulkstudent: PATH_DASHBOARD.studentbulkupload,
  studentcallrequest: PATH_DASHBOARD.StudentRequest,
  feedback: PATH_DASHBOARD.feedback,
  orders: PATH_DASHBOARD.order,
  payments: PATH_DASHBOARD.payment,
  Staff: "/app/staff-manager",
  stafflist: PATH_DASHBOARD.staff,
  uploadbulkstaff: PATH_DASHBOARD.bulkuploadstaff,
  shorts: PATH_DASHBOARD.short,
  grievances: PATH_DASHBOARD.grievances,
  schedules: PATH_DASHBOARD.schedule,
  faq: PATH_DASHBOARD.faq,
  onlyforyou: PATH_DASHBOARD.onlyforyou,
  notice: PATH_DASHBOARD.notice,
  doubts: PATH_DASHBOARD.doubts,
  Revision: "/app/revision",
  revisionlist: PATH_DASHBOARD.revision,
  uploadbulkrevision: PATH_DASHBOARD.uploadbulk,
  questionbank: "/app/questionbank",
  questionbanklist: PATH_DASHBOARD.questionbank,
  questionbulkupload: PATH_DASHBOARD.questionbulkupload,
  Scholarship: "/app/scholarship",
  createscholarship: PATH_DASHBOARD.scholarship,
  createscholarshipclass: PATH_DASHBOARD.scholarshipclass,
  //scholarship test
  scholarshiptest: PATH_DASHBOARD.scholarshiptest,
  createscholarshiptest: PATH_DASHBOARD.createscholarshiptest,
  tophighlight: PATH_DASHBOARD.tophighlight,
  Assignment: "/app/assignment",
  createassignment: PATH_DASHBOARD.assignment,
  assignmentresult: PATH_DASHBOARD.assignmentresult,
  scholarshipapplication: PATH_DASHBOARD.scholorshipapplication,

  Test: "/app/test",
  createtest: PATH_DASHBOARD.testquestion,
  testreport: PATH_DASHBOARD.testreport,
  studentowntest: PATH_DASHBOARD.studentowntest,
  quizreport: PATH_DASHBOARD.quizreportlist,
  Mentorship: "/app/mentorship",
  features: PATH_DASHBOARD.feature,
  howithelp: PATH_DASHBOARD.howithelp,
  whyyouneed: PATH_DASHBOARD.whyyouneed,
  mentorshippackage: PATH_DASHBOARD.mentorshippackage,
  "General Setting": "/app/general-setting",
  instruction: PATH_DASHBOARD.instruction,
  paymentsetting: PATH_DASHBOARD.paymentsetting,
  sitesetting: PATH_DASHBOARD.sitesetting,
  individualsetting: PATH_DASHBOARD.indivisualsetting,
  zoomsetting: PATH_DASHBOARD.zoomsetting,
  firebasesetting: PATH_DASHBOARD.firebasesetting,
  gallarymanager: "/app/gallery",
  Academics: "/app/academics",
  liveclass: PATH_DASHBOARD.liveclass,
  liveclasshistory: PATH_DASHBOARD.history,
  demorequest: PATH_DASHBOARD.dobtdemorequest,
  enquiry: PATH_DASHBOARD.enquiry,
  activitylog: PATH_DASHBOARD.activity,
  Subscription: "/app/subscription",
  packagemaster: PATH_DASHBOARD.package,
  packagedetails: PATH_DASHBOARD.packagesubscription,
  subscriptionplan: PATH_DASHBOARD.SubscriptionList,
};

export const IconsGenerate = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/dynamic/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);
