import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createCityAsync,
  deleteCityByIdAsync,
  getAllCityByStateIdAsync,
  getCityAsync,
  getCityByIdAsync,
  updatCityByIdAsync,
} from "./cities.async";

const initialState = {
  cityLoader: false,
  city: [],
  cityadd: [],
  cityById: [],
  updateCityById: [],
  deleteCityById: [],
  CityByStateId: [],
};

export const citySlice = createSlice({
  name: "city",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getCityAsync.pending,
        createCityAsync.pending,
        getCityByIdAsync.pending,
        updatCityByIdAsync.pending,
        deleteCityByIdAsync.pending,
        getAllCityByStateIdAsync.pending
      ),
      (state) => {
        state.cityLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getCityAsync.fulfilled), (state, action) => {
      state.cityLoader = false;
      state.city = action.payload;
    });
    builder.addMatcher(isAnyOf(createCityAsync.fulfilled), (state, action) => {
      state.cityLoader = false;
      state.cityadd = action.payload;
    });
    builder.addMatcher(isAnyOf(getCityByIdAsync.fulfilled), (state, action) => {
      state.cityLoader = false;
      state.cityById = action.payload.data;
    });
    //for state
    builder.addMatcher(
      isAnyOf(getAllCityByStateIdAsync.fulfilled),
      (state, action) => {
        state.cityLoader = false;
        state.CityByStateId = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(updatCityByIdAsync.fulfilled),
      (state, action) => {
        state.cityLoader = false;
        state.updateCityById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteCityByIdAsync.fulfilled),
      (state, action) => {
        state.cityLoader = false;
        state.deleteCityById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getCityAsync.rejected,
        createCityAsync.rejected,
        getCityByIdAsync.rejected,
        updatCityByIdAsync.rejected,
        deleteCityByIdAsync.rejected,
        getAllCityByStateIdAsync.rejected
      ),
      (state, action) => {
        state.cityLoader = false;
      }
    );
  },
  reducers: {
    emptycity: (state) => initialState,
    emptyByid: (state) => {
      state.cityById = [];
    },
  },
});

export const { emptycity, emptyByid } = citySlice.actions;

export default citySlice.reducer;
